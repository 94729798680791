.nav-wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	background: #f4f4f4;
	transition: 0.5s;
}

nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0 30px 50px;
	max-width: 500px;
	text-align: left;
	position: relative;
}

nav a {
	color: var(--black);
	border: 0;
	font-size: 1em;
	font-weight: 500;
	position: relative;
	display: flex;
	align-items: center;
	// text-align: center;
	text-decoration: none;
	// margin: 0 auto;
}

nav .logo {
	font-weight: bold;
}

.nav__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 50px;
	transition: 0.3s;
	position: relative;
	&:before {
		display: block;
		width: 93%;
		height: 1px;
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background: rgba(112, 112, 112, 0.1);
	}
}

.nav-wrapper.visibleNav {
	transform: translateY(0);
}
.nav-wrapper.invisibleNav {
	transform: translateY(-80px);
}

.right__text {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
	width: 350px;
}
.phone {
	color: #1b1b1b;
	font-weight: bold;
}

.nav-link.active::before {
	display: block;
	width: 100%;
	height: 1px;
	content: "";
	background: #000;

	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translateX(-50%);
	transition: 0.3s;
	animation: appear 0.3s ease-in;
}

.footer-link.active::before {
	display: block;
	width: 100%;
	height: 1px;
	content: "";
	background: #000;

	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translateX(-50%);
	transition: 0.3s;
	animation: appear 0.3s ease-in;
}

.category-link.active::before {
	display: block;
	width: 100%;
	height: 1px;
	content: "";
	background: #000;

	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translateX(-50%);
	transition: 0.3s;
	animation: appear 0.3s ease-in;
}

@keyframes appear {
	0% {
		width: 0px;
		left: 0;
	}

	100% {
		width: 100%;
		left: 50%;
	}
}
