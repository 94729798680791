.button {
	position: relative;
	outline: none;
	background: var(--gray);
	width: 40px;
	height: 40px;
	border: none;
	border-radius: 100px;
	// box-shadow: inset 0 0 0 1px rgba(#000, 0.2), 0 2px 0 rgba($accent, 0.2);
	margin: 12px 0;
	color: var(--black);
	cursor: pointer;
	z-index: 3;
	left: -10px;

	&--large {
		z-index: 1;
		position: relative;
		width: 60px;
		height: 60px;
		z-index: 3;
	}

	&:hover {
		.tooltip {
			top: -100%;
			opacity: 1;
		}
	}
}

// Used by inside <Button />
.icon__burger {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: background 0.3s ease;

	&,
	&:before,
	&:after {
		position: absolute;
		display: block;
		background: var(--black);
		width: 6px;
		height: 6px;
		border-radius: 10px;
	}

	&:before,
	&:after {
		content: "";
		transition: all 100ms ease, right 100ms 100ms ease, left 100ms 100ms ease;
	}

	&:before {
		left: -11px;
		transform: translateX(-50%);
	}

	&:after {
		right: -11px;
		transform: translateX(50%);
	}

	&.active {
		background: transparent;
		transition: background 0.3s ease;

		&,
		&:before,
		&:after {
			height: 3px;
		}

		&:before,
		&:after {
			width: 20px;
			transform-origin: 50% 50%;
			transition: all 100ms ease, width 100ms 100ms ease,
				transform 300ms 200ms cubic-bezier(0.28, 0.55, 0.385, 1.65);
		}

		&:before {
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
		}

		&:after {
			right: 50%;
			transform: translateX(50%) rotate(-45deg);
		}
	}
}

.burger_container {
	position: absolute;
	top: 10px;
	right: -3px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	flex-wrap: nowrap;
	margin: 0 auto;
	transform-origin: center;
}

.nav__toggle {
	position: fixed;
	top: 0;
	left: -100%;
	right: 0;
	bottom: 0;

	width: 100%;
	height: 100%;

	background: #000;
	transition: 0.3s;
	z-index: 2;
	&.active {
		left: 0;
	}
	.container__mob {
		display: flex;
		flex-direction: column;
		padding: 50% 10%;
		margin: 0 auto;
		.nav-link {
			font-size: 50px;
			color: #fff;
		}
		.logo {
			font-size: 20px;
			line-height: 22px;
			font-weight: bold;
			color: #fff;

			position: absolute;
			right: 42px;
			top: 61px;
		}
	}
}

a.logo__mob {
	font-size: 20px;
	line-height: 22px;
	font-weight: bold;
	color: var(--black);

	position: absolute;
	right: 42px;
	top: 61px;
}
