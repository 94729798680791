.leaflet-container {
  height: 100vh;
  width: 100%;
}

#locate-position {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 240px;
  left: 50px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.leaflet-marker-icon {
  transition: 2s;
  transform: rotate(-45deg);
}

.leaflet-container {
  z-index: 0;
}
