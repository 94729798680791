:root {
  --black: #1b1b1b;
  --gray: #f4f4f4;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
}

body::-webkit-scrollbar {
  width: 0px;
}

.wrapper {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 102vh;

  &::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-width: 768px) {
    padding: 30px;
    height: 100vh;
  }
}

.container {
  max-width: 1700px;
  margin: 0 auto;
  padding: 50px 100px;
  &.web {
    margin: 150px auto 100px;
  }
  &.mob {
    padding: 0;
    margin: 50px auto 0;
  }
  @media (max-width: 768px) {
    padding: 0;
    margin: 50px auto 0;
  }
}

.text-center {
  text-align: center;
}

.center {
  max-width: 1700px;
  padding: 0 50px;
  margin: 0 auto;
}

h1 {
  font-size: 70px;
  color: var(--black);
}

h2 {
  color: var(--gray);
}

.heading {
  margin: 100px 0;
  @media (max-width: 768px) {
    padding: 0;
    margin: 0 auto 100px;
  }
  .title {
    text-align: left;
    max-width: 500px;
    @media (max-width: 768px) {
      padding: 0;
      margin: 0 auto 0;
      font-size: 30px;
    }
  }
}
.heading__small {
  margin: 100px 0 50px;
  .title {
    max-width: 500px;
  }
}

.black {
  background: #1b1b1b;
  color: #fff;
}

h3.dark {
  font-size: 40px;
  color: #1b1b1b;
}

.category__img {
  width: 50%;
  margin: 0 auto;
}
